// Import custom CSS
import "../Scss/App.scss";

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';
import Theme from "./Components/Theme";
import Loading from "./Components/Loading";

const theme = new Theme();
const loading = new Loading();

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
