export default class Theme {
    private readonly html: HTMLElement;
    private readonly themeSwitch: HTMLElement;
    private readonly themeIcon: HTMLElement;
    private readonly themeLogos: NodeListOf<HTMLElement>;

    constructor() {
        this.html = document.querySelector('html');
        this.themeSwitch = document.querySelector('[data-theme-switch]');
        this.themeIcon = document.querySelector('[data-theme-switch] i.fa-solid');
        this.themeLogos = document.querySelectorAll('[data-theme-logo]');

        this.registerEvents();
        this.init();
    }

    private registerEvents(): void {
        // Register event for theme (dark / light) button
        if (this.themeSwitch) {
            this.themeSwitch.querySelector('input').addEventListener(
                'change',
                function (e: Event) {
                    let logo = '';
                    let newIcon = '';
                    let newValue = '';
                    let currentValue = this.html.getAttribute('data-bs-theme');

                    if (currentValue === 'dark') {
                        newValue = 'light';
                        newIcon = 'fa-sun';
                        logo = this.themeSwitch.getAttribute('data-bwt-logoLight');
                    } else {
                        newValue = 'dark';
                        newIcon = 'fa-moon';
                        logo = this.themeSwitch.getAttribute('data-bwt-logoDark');
                    }

                    this.html.setAttribute('data-bs-theme', newValue);
                    this.themeIcon.classList.remove('fa-moon', 'fa-sun');
                    this.themeIcon.classList.add(newIcon);

                    if (this.themeLogos) {
                        for (let i = 0; i < this.themeLogos.length; i++) {
                            this.themeLogos[i].setAttribute('src', logo);
                        }
                    }

                    document.cookie = `theme=${newValue}; path=/; max-age=${60 * 60 * 24 * 7 * 52};`;
                }.bind(this)
            );
        }
    }

    private init(): void {
        let theme = this.getCookie('theme');
        if (theme === undefined) {
            document.cookie = `theme=dark; path=/; max-age=${60 * 60 * 24 * 7 * 52};`;
        }
        theme = this.getCookie('theme');
        this.html.setAttribute('data-bs-theme', theme);

        let logo = '';
        let icon = '';
        if (theme === 'dark') {
            icon = 'fa-moon';
            this.themeSwitch.querySelector('input').checked = true;
            logo = this.themeSwitch.getAttribute('data-bwt-logoDark');
        } else {
            icon = 'fa-sun';
            this.themeSwitch.querySelector('input').checked = false;
            logo = this.themeSwitch.getAttribute('data-bwt-logoLight');
        }

        if (this.themeLogos) {
            for (let i = 0; i < this.themeLogos.length; i++) {
                this.themeLogos[i].setAttribute('src', logo);
            }
        }

        this.themeIcon.classList.remove('fa-moon', 'fa-sun');
        this.themeIcon.classList.add(icon);
    }

    /**
     * Get the value of the cookie of the given name.
     */
    private getCookie(name: string) {
        let value: string = `; ${document.cookie}`;
        let parts: string[] = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        }
    }
}
