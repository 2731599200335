export default class Loading {
    private readonly loadingIndicator: HTMLElement;
    private readonly loadingButtons: NodeListOf<HTMLElement>;
    private readonly loadingForms: NodeListOf<HTMLElement>;

    constructor() {
        this.loadingIndicator = document.querySelector('div[data-bwt-loading]');
        this.loadingButtons = document.querySelectorAll('.btn[data-loading]');
        this.loadingForms = document.querySelectorAll('form[data-loading]');
        this.registerEvents();
    }

    private registerEvents(): void {
        if (this.loadingButtons && this.loadingIndicator) {
            for (let i = 0; i < this.loadingButtons.length; i++) {
                this.loadingButtons[i].addEventListener(
                    'click',
                    function (e: Event) {
                        this.loadingIndicator.classList.remove('d-none');
                    }.bind(this)
                );
            }
        }
        if (this.loadingForms && this.loadingIndicator) {
            for (let i = 0; i < this.loadingForms.length; i++) {
                this.loadingForms[i].addEventListener(
                    'submit',
                    function (e: Event) {
                        this.loadingIndicator.classList.remove('d-none');
                    }.bind(this)
                );
            }
        }
    }
}
